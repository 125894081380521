


































































  import { PRODUCTS } from '@/constant/Enums';
  import { ConfigModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';
  import { RAJA_PREMI_INFO } from "@/constant/Enums";

  @Component({
    name: 'Footer',
    components: {},
    mixins: [],
  })
  export default class Footer extends Vue {
    public LIST_PRODUCT = PRODUCTS;
    listSosmed = {
      'twitter': 'https://twitter.com/RajaPremi_com',
      'facebook': 'https://www.facebook.com/myrajapremi',
      'instagram': 'https://www.instagram.com/rajapremi_id',
      'youtube': 'https://www.youtube.com/channel/UCj3JIxwxcMK72CQvnTBvXkg',
      'linkedin': 'https://www.linkedin.com/company/3363629',
      // 'google': ''
    }

    @State('configs') configs!: ConfigModel[];

    public RAJA_PREMI_INFO = RAJA_PREMI_INFO

    public get contactInfo(): string {
      return this.configs.filter((item) => item.sys_config_id === '1')[0]?.value2.replace('h3', 'h5');
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
